//override any class here

a {
    color: goldenrod;
}
a:hover {
    color: gold;
}

.main-logo {
    height: 30px;
}